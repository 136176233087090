<template>
  <div>
    <b-card
      no-body
      class="card-payment"
    >
      <b-card-header>
        <b-card-title>Exchange Rate</b-card-title>
      </b-card-header>
      <b-card-body>
        <div v-if="!loading">
          <b-table-simple
            v-for="(item, index) in data"
            :key="`list-item-${index}`"
            hover
            class="mb-4"
          >
            <b-tbody>
              <b-tr
                v-for="(itemField, indexField) in field"
                :key="`list-field-${indexField}-${index}`"
              >
                <b-th width="40%">
                  {{ itemField.title }}
                </b-th>
                <b-td width="40%">
                  <span
                    v-if="itemField.title.toLowerCase() === 'currency'"
                    class="text-uppercase"
                  >{{ item[itemField.tag] }}</span>
                  <span v-else-if="typeof item[itemField.tag] === 'number'">{{ item[itemField.tag] | number }}</span>
                  <span v-else>{{ item[itemField.tag] }}</span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div v-else>
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BSkeleton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      field: [
        {
          tag: 'currency',
          title: 'Currency',
        },
        {
          tag: 'price',
          title: 'Price',
        },
        {
          tag: 'processingFeePercentage',
          title: 'Processing Fee Percentage',
        },
        {
          tag: 'finalPrice',
          title: 'Final Price',
        },
        {
          tag: 'processingFee',
          title: 'Processing Fee',
        },
        {
          tag: 'totalPrice',
          title: 'Total Price',
        },
      ],
    }
  },
  computed: {
    loading() {
      return this.$store.state.exchangeRate.loadingDetail
    },
    data() {
      return this.$store.state.exchangeRate.data
    },
  },
  mounted() {
    this.$store.dispatch('exchangeRate/getData', this.$store.state.exchangeRate.countryCode)
  },
}
</script>
